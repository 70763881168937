<template>
  <div class="w-100">
    <v-layout wrap row p-relative project-item>
      <v-flex xs6 v-if="showCompanySelect">
        <InputMultSelect
          v-model="project.selectedCompanies"
          :items="selectedCompanyList"
          textLabel="Apoio (Responsáveis)"
          helpLabel="Opcional"
          valueAttribute="id"
          textAttribute="entity.name"
          :required="false"
        ></InputMultSelect>
      </v-flex>
      <v-flex xs12>
        <v-layout row p-relative project-item>
          <v-flex shrink pr-3 img-box>
            {{ file  ? file : 'Não tem nada'}}
            <FileImage :file="file" />
          </v-flex>
          <v-flex content-box xs12 w-100>
            <h5>{{ institution }}</h5>
            <h3>{{ projectShortName }}</h3>
            <p>{{ projectObjective }}</p>
            <h4 class="yellow">{{ projectTypeName }}</h4>
          </v-flex>
          <v-flex class="option-box" v-if="canDelete">
            <!-- será adicionado posteriormente -->
            <!--<a href="#" @click.prevent="$emit('hide')">
              <i class="fal fa-eye"></i>
            </a>-->
            <a href="#" @click.prevent="$emit('delete')">
              <i class="fal fa-trash"></i>
            </a>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script type="plain/text">
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import Project from "@/scripts/models/project.model.js";
import FileImage from "@/components/FileImage.vue";
export default {
  components: {
    FileImage
  },
  props: {
    project: null,
    institutionList: null,
    companyList: null,
    canDelete: true,
    showCompanySelect: false
  },
  computed: {
    file() {
      if (this.project != null && this.project.projectLandingPageConfig != null)
        return this.project.projectLandingPageConfig.heroFile;
      return null;
    },
    institution() {
      if (this.institutionList != null && this.project != null) {
        for (let i = 0; i < this.institutionList.length; i++) {
          if (this.institutionList[i].id == this.project.institutionId) {
            return this.institutionList[i].entity.name;
          }
        }
      }
      return "";
    },
    projectShortName() {
      if (this.project != null) return this.project.shortName;
      return "";
    },
    projectObjective() {
      if (this.project != null) return this.project.objective;
      return "";
    },
    projectTypeName() {
      if (this.project != null)
        return GetProjectTypeName(this.project.projectTypeId);
      return "";
    },
    selectedCompanyList() {
      if (this.companyList) {
        return this.companyList.filter(function(item) {
          return item.selected == true;
        });
      }
    }
  }
};
</script>

<style>
</style>