<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon
              type="fal"
              name="long-arrow-left"
              size="24"
              colorFont="#cfd2dd"
            />
          </a>
          <h1>CAMPANHA</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section">
            <!-- section head -->
            <div class="head mt-0">
              <h4>Dados Iniciais</h4>
            </div>
            <!-- conteudo -->
            <div class="form-grid">
              <v-layout row wrap mw-total>
                <v-flex
                  class="upload-box-profile-father"
                  style="width: 300px !important"
                  shrink
                  pr-3
                >
                  <div
                    class="upload-image bg-contain"
                    style="width: 300px"
                    id="preview"
                    ref="preview"
                  >
                    <label for="upload-campaign">
                      <i class="fas fa-camera-alt"></i>
                    </label>
                    <label
                      for="upload-campaign"
                      class="do-upload"
                      v-show="
                        campaign.file.path == '' || campaign.file.path == null
                      "
                    >
                      <i class="fal fa-cloud-upload"></i>Logo da Campanha
                    </label>
                    <input
                      type="file"
                      id="upload-campaign"
                      accept="image/*"
                      @change="fileChanged"
                      ref="campaign_upload"
                    />
                  </div>
                </v-flex>
                <v-flex form-grid>
                  <v-layout column>
                    <v-flex>
                      <InputText
                        ref="nameInput"
                        v-model="campaign.name"
                        type="text"
                        place-holder="Nome"
                        textLabel="Nome da Campanha"
                        invalidInputMessage="Preencha o nome da campanha"
                        labelCharLimit="50"
                      />
                    </v-flex>
                    <v-flex>
                      <InputDateRange
                        ref="initialFinalDatesInput"
                        v-model="initialFinalDates"
                        textLabel="Vigência da Campanha"
                        place-holder="Data vigência da campanha"
                        :monthsToShow="2"
                        invalidInputMessage="Preencha a vigência da campanha"
                      />
                    </v-flex>
                    <v-flex>
                      <InputSelect
                        ref="campaignTypeInput"
                        :items="CampaignTypesList"
                        valueAttribute="id"
                        textAttribute="name"
                        v-model="campaignTypeId"
                        textLabel="Tipo da Campanha"
                      ></InputSelect>
                    </v-flex>
                    <v-flex v-if="campaignTypeId === 4">
                      <p class="text-color">
                        Permitir múltiplas inscrições de um mesmo usuário?
                      </p>
                      <v-radio-group
                        v-model="campaign.subscribeMoreThanOneTime"
                      >
                        <v-radio label="Sim" :value="true"></v-radio>
                        <v-radio label="Não" :value="false"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <BorderSeparation />

              <div class="head mt-0">
                <h4>Configurações para compartilhamento</h4>
              </div>

              <v-layout row wrap mw-total>
                <v-flex
                  class="upload-box-profile-father"
                  style="width: 200px !important; height: 160px"
                  shrink
                  pr-3
                >
                  <div
                    class="upload-image bg-contain"
                    style="width: 200px; min-height: 160px"
                    id="share"
                    ref="share"
                  >
                    <label for="upload-campaign-share">
                      <i class="fas fa-camera-alt"></i>
                    </label>
                    <label
                      for="upload-campaign_share"
                      class="do-upload"
                      v-show="
                        campaign.faviconFile.path == '' ||
                        campaign.faviconFile.path == null
                      "
                    >
                      <i class="fal fa-cloud-upload"></i>Ícone
                    </label>
                    <input
                      type="file"
                      id="upload-campaign_share"
                      accept="image/*"
                      @change="faviconFileChanged"
                      ref="campaign_upload_share"
                    />
                  </div>
                </v-flex>

                <v-flex md8>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <InputText
                        ref="metaTitleInput"
                        v-model="campaign.metaTitle"
                        type="text"
                        place-holder="Título"
                        textLabel="Título"
                        labelCharLimit="60"
                        :required="false"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <InputTextArea
                        ref="metaDescriptionInput"
                        v-model="campaign.metaDescription"
                        labelAreaCharLimit="160"
                        rows="3"
                        place-holder="Descrição"
                        textLabel="Descrição"
                        :required="false"
                      />
                    </v-flex>
                  </v-layout>

                  <hr class="sm my-3" />
                  <v-layout row wrap>
                    <v-flex f-size-14 caption text-gray-dark mt-1 xs12>
                      O upload da imagem de compartilhamento e favicon deve ter
                      até 500kb e ficará como exibido abaixo.
                    </v-flex>
                    <v-flex caption text-gray-dark mt-1 xs12
                      >Preview fav-icon: Google Chrome</v-flex
                    >
                    <v-flex mt-2>
                      <div class="browser-bg light">
                        <span
                          class="fav-icon-preview"
                          :data-text="campaign.metaTitle"
                          id="favIconPreview1"
                          ref="favIconPreview1"
                        ></span>
                      </div>
                    </v-flex>
                    <v-flex mt-2>
                      <div class="browser-bg dark">
                        <span
                          class="fav-icon-preview"
                          :data-text="campaign.metaTitle"
                          id="favIconPreview2"
                          ref="favIconPreview2"
                        ></span>
                      </div>
                    </v-flex>

                    <v-flex caption text-gray-dark mt-1 xs12
                      >Preview Compartilhamento: Whatsapp</v-flex
                    >
                    <PreviewShare
                      :title="campaign.metaTitle"
                      :description="campaign.metaDescription"
                      :image-path="campaign.faviconFile.path || ''"
                      :subdomain="campaign.subdomain"
                    ></PreviewShare>
                  </v-layout>
                </v-flex>
              </v-layout>

              <BorderSeparation />

              <div>
                <div class="head mt-0">
                  <h4>Google Analytics e Tag Manager</h4>
                </div>

                <v-layout row wrap mw-half>
                  <v-flex form-grid>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <InputText
                          ref="gaInput"
                          v-model="campaign.googleAnalyticsId"
                          type="text"
                          place-holder="Ex.: UA-146441234-3"
                          textLabel="GA - ID"
                          labelCharLimit="20"
                          :required="false"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout row wrap mw-half>
                  <v-flex form-grid>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <InputText
                          ref="gtmInput"
                          type="text"
                          v-model="campaign.googleTagManager"
                          place-holder="Ex.: GTM-NXNZT4C"
                          textLabel="GTM - ID"
                          labelCharLimit="20"
                          :required="false"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout row wrap mw-half>
                  <v-flex form-grid>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <InputText
                          ref="fbInput"
                          type="text"
                          v-model="campaign.facebookPixelId"
                          place-holder="Ex.: 0123456789"
                          textLabel="Facebook Pixel Id"
                          labelCharLimit="20"
                          :required="false"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <BorderSeparation />
              </div>

              <div class="head mt-0">
                <h4>Configurações da campanha</h4>
              </div>
              <v-layout row mw-half>
                <v-flex>
                  <InputMoney
                    ref="minimumDonationValueInput"
                    v-model="campaign.minimumDonationValue"
                    textLabel="Qual será o valor mínimo da doação?"
                    invalidInputMessage="Preencha o valor mínimo de doação"
                    :precision="0"
                  />
                </v-flex>
              </v-layout>
              <v-layout row mw-half>
                <v-flex>
                  <InputMoney
                    ref="objetiveDonationValueInput"
                    v-model="campaign.objetiveDonationValue"
                    textLabel="Qual será o objetivo de captação de doações nesta campanha?"
                    invalidInputMessage="Preencha o valor objetivo de doação"
                    :precision="0"
                    :required="campaign.campaignTypeId != CampaignTypes.Ticket"
                  />
                </v-flex>
              </v-layout>
              <v-layout row mw-half>
                <v-flex>
                  <InputSelect
                    :items="receiver"
                    v-model="campaign.receiver"
                    valueAttribute="id"
                    textAttribute="text"
                    textLabel="Recebedor"
                  ></InputSelect>
                </v-flex>
              </v-layout>
              <v-layout row mw-half>
                <v-flex>
                  <InputSelect
                    :items="[
                      { id: null, name: 'Sem integração' },
                      { id: 1, name: 'OSESP' },
                    ]"
                    valueAttribute="id"
                    textAttribute="name"
                    v-model="campaign.salesForceIntegrationId"
                    textLabel="Integração com SalesForce"
                  ></InputSelect>
                </v-flex>
              </v-layout>
              <v-layout row mw-half
                v-if="campaign.campaignTypeId === CampaignTypes.Incentivada">
                <v-flex form-grid>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <InputText
                          ref="simulatorPercentage"
                          type="text"
                          v-model="campaign.simulatorPercentage"
                          place-holder="10"
                          mask="###"
                          textLabel="Porcentagem de dedução no imposto de renda"
                          :required="false"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
              </v-layout>

              <!-- ITEM exemplo de como deve ser um item com switch-->
              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  Doações parceladas poderão passar para o ano seguinte?
                </v-flex>
                <v-switch
                  v-model="campaign.donationForTheNextYear"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <!-- Usar Divider entre itens -->
              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  A campanha permitirá doar para mais de um produto por vez?
                </v-flex>
                <v-switch
                  v-model="campaign.donateToMoreThanOneProduct"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  CPF é obrigatório para entrar nessa campanha?
                </v-flex>
                <v-switch
                  v-model="campaign.cpfIsRequired"
                  @change="changeToFalseCpfIsRequired()"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3> Permitir doação por CNPJ? </v-flex>
                <v-switch
                  v-model="campaign.permitCnpjDonation"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  Abrir modal de confirmação de dados ao realizar login?
                </v-flex>
                <v-switch
                  v-model="campaign.modalConfirmationOnDonationActive"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-divider></v-divider>
              <!--
              <v-flex mt-4>
                <p class="text-color">
                  Doações parceladas poderão passar para o ano seguinte?
                </p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.donationForTheNextYear">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex mt-4>
                <p class="text-color">
                  A campanha permitirá doar para mais de um produto por vez?
                </p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.donateToMoreThanOneProduct">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio
                    label="Não"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex mt-4>
                <p class="text-color">
                  CPF é obrigatório para entrar nessa campanha?
                </p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.cpfIsRequired">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio
                    label="Não"
                    :value="false"
                    @click="changeToFalseCpfIsRequired()"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex mt-4>
                <p class="text-color">Permitir doação por CNPJ?</p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.permitCnpjDonation">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex mt-4>
                <p class="text-color">
                  Abrir modal de confirmação de dados ao realizar login?
                </p>
              </v-flex>

            
              <v-flex>
                <v-radio-group
                  v-model="campaign.modalConfirmationOnDonationActive"
                >
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              -->
              <div v-if="campaign.modalConfirmationOnDonationActive">
                <v-flex mt-4>
                  <div>
                    Campos a serem informados na modal de confirmação de dados:
                  </div>
                </v-flex>
                <v-flex>
                  <div class="checkbox-father mw-total">
                    <InputCheckBoxList
                      ref="checkboxList"
                      :list="checkboxListInformModal"
                      v-model="checkboxListSelected"
                      labelAttribute="name"
                      valueAttribute="id"
                      :columns="3"
                      @input="changeCheckboxListInform"
                    />
                  </div>
                </v-flex>
              </div>

              <v-divider></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  Abrir modal de confirmação de dados de doação Simplificada?
                </v-flex>
                <v-switch
                  v-model="campaign.allowAnnonymousDonation"
                  @change="
                    SetAnnonymousDonationToUnchecked(
                      campaign.allowAnnonymousDonation
                    )
                  "
                  class="shrink"
                ></v-switch>
              </v-layout>
              <div v-if="campaign.allowAnnonymousDonation">
                <v-flex mt-4>
                  <p>
                    Campos a serem informados na modal de doação Simplificada:
                  </p>
                </v-flex>
                <v-flex class="checkbox-father mw-total">
                  <InputCheckBoxList
                    ref="checkboxList"
                    :list="checkboxListInformModalSimplified"
                    v-model="checkboxListSelectedSimplified"
                    labelAttribute="name"
                    valueAttribute="id"
                    :columns="3"
                    @input="changeCheckboxListInformSimplified"
                  />
                </v-flex>
              </div>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3>
                  Permitir que doadores informem um código de indicação?
                </v-flex>
                <v-switch
                  v-model="campaign.memberGetMemberActive"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3> Ativar numeração de sorteio? </v-flex>
                <v-switch
                  v-model="campaign.activeDraw"
                  class="shrink"
                ></v-switch>
              </v-layout>
              <v-layout row mw-half v-if="campaign.activeDraw">
                <v-flex>
                  <InputMoney
                    :precision="0"
                    ref="valueDonationByNumberDrawInput"
                    v-model="campaign.valueDonationByNumberDraw"
                    type="number"
                    prefix="R$ "
                    place-holder="Digite o valor"
                    textLabel="Valor por número de sorteio"
                    invalidInputMessage="Preencha com o valor"
                    :required="campaign.activeDraw"
                  />
                </v-flex>
              </v-layout>

              <v-divider class="mw-half"></v-divider>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3> Ativar uso de Recaptcha no login? </v-flex>
                <v-switch
                  v-model="campaign.withRecaptcha"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <v-layout mw-half align-center gap pb-2>
                <v-flex grow mt-3> Permitir doação em dolar? </v-flex>
                <v-switch
                  v-model="campaign.allowUsdDonation"
                  class="shrink"
                ></v-switch>
              </v-layout>

              <!--
              <v-flex mt-4>
                <p>
                  Permitir que doadores informem um código de indicação?
                </p>
              </v-flex>
              <v-flex>
                <v-radio-group
                  v-model="campaign.allowAnnonymousDonation"
                  @change="
                    SetAnnonymousDonationToUnchecked(
                      campaign.allowAnnonymousDonation
                    )
                  "
                >
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              <div v-if="campaign.allowAnnonymousDonation">
                <v-flex mt-4>
                  <p>
                    Campos a serem informados na modal de doação Simplificada:
                  </p>
                </v-flex>
                <v-flex class="checkbox-father mw-total">
                    <InputCheckBoxList
                      ref="checkboxList"
                      :list="checkboxListInformModalSimplified"
                      v-model="checkboxListSelectedSimplified"
                      labelAttribute="name"
                      valueAttribute="id"
                      :columns="3"
                      @input="changeCheckboxListInformSimplified"
                    />
                </v-flex>
              </div>
              <v-flex mt-4>
                <p>
                  Permitir que doadores informem um código de indicação?
                </p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.memberGetMemberActive">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex mt-4>
                <p>Ativar numeração de sorteio?</p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.activeDraw">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-layout row mw-half v-if="campaign.activeDraw">
                <v-flex>
                  <InputMoney
                    :precision="0"
                    ref="valueDonationByNumberDrawInput"
                    v-model="campaign.valueDonationByNumberDraw"
                    type="number"
                    prefix="R$ "
                    place-holder="Digite o valor"
                    textLabel="Valor por número de sorteio"
                    invalidInputMessage="Preencha com o valor"
                    :required="campaign.activeDraw"
                  />
                </v-flex>
              </v-layout>
              <v-flex mt-4>
                <p class="text-color">Ativar uso de Recaptcha no login?</p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.withRecaptcha">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>

            -->
              <v-divider></v-divider>

              <v-flex mt-4>
                Tentativas de login antes de bloquear o usuário
              </v-flex>
              <v-flex xs5>
                <InputText
                  type="text"
                  v-model="campaign.loginAttempts"
                  place-holder="Tentativas de login"
                />
              </v-flex>
              <v-flex caption text--secondary mb-3>
                O valor 0 desabilita o bloqueio de usuário
              </v-flex>

              <v-divider></v-divider>

              <v-flex mt-4> Quem poderá participar desta campanha? </v-flex>
              <v-flex mb-3>
                <v-radio-group v-model="campaign.isPublic">
                  <v-radio
                    label="Qualquer pessoa (Campanha Pública) ou campanha mista."
                    :value="true"
                  ></v-radio>
                  <v-radio
                    label="Somente membros da empresa/entidade (Campanha Restrita)"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <!-- <v-flex mt-4>
                              <p class="text-color">Permitir doação anônima?</p>
                            </v-flex>
                            <v-flex>
                              <v-radio-group v-model="campaign.allowAnnonymousDonation">
                                <v-radio label="Sim" :value="true"></v-radio>
                                <v-radio label="Não" :value="false"></v-radio>
                              </v-radio-group>
              </v-flex>-->

              <v-divider></v-divider>
              <div class="widget-flex">
                <div class="checkbox-father mw-half">
                  <v-flex mt-3>
                      Responsável pelas taxas de <strong>Pix</strong>?
                    </v-flex>
                    <v-flex mb-3>
                      <v-radio-group v-model="campaign.taxOwnerPix">
                        <v-radio label="A própria empresa" :value="1"></v-radio>
                        <v-radio label="A pessoa doadora" :value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                </div>
                <div class="checkbox-father mw-half">
                  <v-flex mt-3>
                      Responsável pelas taxas de <strong>Boleto</strong>?
                    </v-flex>
                    <v-flex mb-3>
                      <v-radio-group v-model="campaign.taxOwnerTicket">
                        <v-radio label="A própria empresa" :value="1"></v-radio>
                        <v-radio label="A pessoa doadora" :value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                </div>
                <div class="checkbox-father mw-half">
                  <v-flex mt-3>
                      Responsável pelas taxas de <strong>Cartão de Crédito</strong>?
                    </v-flex>
                    <v-flex mb-3>
                      <v-radio-group v-model="campaign.taxOwnerCreditCard">
                        <v-radio label="A própria empresa" :value="1"></v-radio>
                        <v-radio label="A pessoa doadora" :value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                </div>
              </div>

              <v-divider class="mw-half"></v-divider>

              <!--
              <v-flex mt-4>
                <p class="text-color">Permitir doação em dolar?</p>
              </v-flex>
              <v-flex>
                <v-radio-group v-model="campaign.allowUsdDonation">
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
              </v-flex>

            -->

              <div
                v-if="
                  hasPermission(
                    [permissions.CRUDCampaignAll],
                    this.$route.query.entityId
                  )
                "
              >
                <v-flex mt-4>
                  <p class="text-color">
                    Qual o convênio a ser utilizado nos pagamentos via boleto?
                  </p>
                </v-flex>
                <v-flex>
                  <v-radio-group v-model="ticketConfigDefault">
                    <v-radio label="Convênio padrão" :value="true"></v-radio>
                    <v-radio
                      label="Especificar um convênio"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-layout v-if="!ticketConfigDefault" mw-half>
                  <v-flex mt-2 ml-4>
                    <inputSelect
                      :items="ticketConfigs"
                      v-model="ticketConfigId"
                      ref="ticketCodeInput"
                      invalidInputMessage="Selecione um dos convênios"
                      valueAttribute="id"
                      textAttribute="description"
                      unselectedValue="null"
                      unselectedText="Selecione o convênio"
                    />
                  </v-flex>
                </v-layout>
                <v-flex mt-4>
                  <p class="text-color">Utilizar termo de anuência padrão?</p>
                </v-flex>
                <v-flex>
                  <v-radio-group v-model="campaign.consentTermDefault">
                    <v-radio label="Sim" :value="true"></v-radio>
                    <v-radio label="Não" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-layout v-if="!campaign.consentTermDefault" mw-half>
                  <v-flex mt-2>
                    <p>
                      <v-btn
                        class="btn-primary white--text"
                        @click="addConsentTerm"
                        >Adicionar</v-btn
                      >
                    </p>
                    <v-layout
                      align-start
                      v-for="(
                        consentTerm, index
                      ) in campaign.campaignConsentTerms"
                      :key="consentTerm.id"
                    >
                      <v-flex xs3 sm2>
                        <v-btn
                          flat
                          icon
                          color="pink"
                          class="mt-1"
                          @click="removeConsentTerm(index)"
                        >
                          <v-icon dark>delete_outline</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex>
                        <InputSelect
                          :items="paymentMethodList"
                          v-model="consentTerm.paymentMethodId"
                          textLabel="Método de pagamento"
                          valueAttribute="id"
                          textAttribute="name"
                        />
                        <InputTextArea
                          textLabel="Termo de anuência"
                          v-model="consentTerm.term"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>

              <v-divider class="mb-3"></v-divider>

              <h4 class="mt-4 mb-5">Cadastro de API's</h4>

              <v-layout column gap-xl ml-0 mt-3 mw-half>
                <v-text-field
                  label="Nome da API"
                  type="text"
                  v-model="apiRegistration.apiName"
                ></v-text-field>
                <v-text-field
                  label="URL da API"
                  place-holder="Ex: https://exemplo.com.br/buscafuncionarioporcpf?cpf="
                  type="text"
                  v-model="apiRegistration.url"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de CPF do funcionário conforme a API"
                  type="text"
                  place-holder="Ex: cpf, document..."
                  v-model="apiRegistration.cpf"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de Nome do funcionário conforme a API"
                  type="text"
                  place-holder="Ex: name, nome..."
                  v-model="apiRegistration.name"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de E-mail do funcionário conforme a API"
                  type="text"
                  place-holder="Ex: email, e-mail..."
                  v-model="apiRegistration.email"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de Matrícula do funcionário conforme a API"
                  type="text"
                  place-holder="Ex: id, matricula, registration..."
                  v-model="apiRegistration.registration"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de Departamento do funcionário conforme a API"
                  type="text"
                  place-holder="Ex: departament, departamento..."
                  v-model="apiRegistration.departament"
                ></v-text-field>
                <v-text-field
                  label="Cadastre o campo de CNPJ do funcionário conforme a API"
                  hint="Importante que seja o da matriz"
                  persistent-hint
                  type="text"
                  place-holder="Ex: cnpj, empresa_ducoment..."
                  v-model="apiRegistration.cnpj"
                ></v-text-field>
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary flex-inherit px-5 ml-auto"
                  large
                  @click="addApiRegistration()"
                  >Adicionar</v-btn
                >

                <div>
                  <v-layout>
                    <div
                      class="list-scroll m-height-170 mt-3"
                      v-for="(api, index) in apiRegistrationList"
                      :key="api.id"
                    >
                      <div>
                        Dados do Json
                        <div>
                          <p>
                            {{ api.apiName }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.cpf }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.name }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.email }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.registration }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.departament }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.cnpj }}
                          </p>
                        </div>
                        <div>
                          <p>
                            {{ api.url }}
                          </p>
                        </div>
                        <div>
                          <a href="#" @click="removeApiRegistration(index)">
                            <i class="fal fa-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </v-layout>
                </div>
              </v-layout>

              <BorderSeparation />
              <v-layout>
                <div class="section">
                  <div class="head mt-0">
                    <h4>Meios de pagamento - Gateway de pagamentos</h4>
                    <p class="text-color mt-3">
                      Selecione o provedor de pagamentos para as doações no
                      <strong>cartão</strong>
                    </p>
                  </div>
                  <div class="checkbox-father mw-total">
                    <InputSelect
                      :items="
                        getProvidersByPaymentMethod(
                          paymentMethodEnum.CreditCard
                        )
                      "
                      v-model="
                        campaign.campaignPaymentMethodProvider
                          .creditCardPaymentMethodProviderId
                      "
                      valueAttribute="id"
                      textAttribute="name"
                      unselectedValue="0"
                      :useAnotherDefaultValue="true"
                    ></InputSelect>
                  </div>
                  <p class="text-color mt-3">
                    Selecione o provedor de pagamentos para as doações via
                    <strong>boleto</strong>
                  </p>
                  <div class="checkbox-father mw-total">
                    <InputSelect
                      :items="
                        getProvidersByPaymentMethod(paymentMethodEnum.Ticket)
                      "
                      v-model="
                        campaign.campaignPaymentMethodProvider
                          .ticketPaymentMethodProviderId
                      "
                      valueAttribute="id"
                      textAttribute="name"
                      unselectedValue="0"
                      :useAnotherDefaultValue="true"
                    ></InputSelect>
                  </div>
                  <p class="text-color mt-3" v-if="campaignId == 53">
                    Selecione o provedor de pagamentos para as doações via
                    <strong>débito em conta</strong>
                  </p>
                  <div class="checkbox-father mw-total" v-if="campaignId == 53">
                    <InputSelect
                      :items="
                        getProvidersByPaymentMethod(
                          paymentMethodEnum.AccountDebit
                        )
                      "
                      v-model="
                        campaign.campaignPaymentMethodProvider
                          .accountDebitPaymentMethodProviderId
                      "
                      valueAttribute="id"
                      textAttribute="name"
                      unselectedValue="0"
                      :useAnotherDefaultValue="true"
                    ></InputSelect>
                  </div>
                  <p class="text-color mt-3">
                    Selecione o provedor de pagamentos para as doações via
                    <strong>pix</strong>
                  </p>
                  <div class="checkbox-father mw-total">
                    <InputSelect
                      :items="
                        getProvidersByPaymentMethod(paymentMethodEnum.Pix)
                      "
                      v-model="
                        campaign.campaignPaymentMethodProvider
                          .pixPaymentMethodProviderId
                      "
                      valueAttribute="id"
                      textAttribute="name"
                      unselectedValue="0"
                      :useAnotherDefaultValue="true"
                    ></InputSelect>
                  </div>
                </div>
              </v-layout>
              <BorderSeparation />
              <v-layout>
                <div class="section">
                  <div class="head mt-0">
                    <h4>Meios de pagamento - Público Externo</h4>
                    <h2 class="alert-campaign-edit">
                      Somente para público “não funcionário"
                    </h2>
                  </div>
                  <div class="checkbox-father mw-total">
                    <InputCheckBoxList
                      ref="paymentMethodListInput"
                      :list="publicPaymentMethodList"
                      v-model="campaign.paymentMethodList"
                      labelAttribute="name"
                      valueAttribute="id"
                      :columns="3"
                    />
                  </div>
                </div>
              </v-layout>
              <!-- <template v-if="campaign.campaignTypeId != CampaignTypes.Ticket "> -->
              <v-layout>
                <p class="text-color mt-3">
                  Recorrências de doação permitidas -
                  <small>Até 3 opções</small>
                </p>
              </v-layout>
              <v-layout>
                <v-flex>
                  <div class="checkbox-father mw-total">
                    <InputCheckBoxListWithMoneyInput
                      ref="donationRecurrencePeriodListInput"
                      :list="donationRecurrencePeriodList"
                      v-model="campaign.donationRecurrencePeriodList"
                      labelAttribute="name"
                      valueAttribute="id"
                      :numberOfElementsPerColumn="[3, 2, 2]"
                      :columns="3"
                      :listSizeLimit="3"
                    />
                  </div>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex
                  v-if="
                    campaign.paymentMethodList.includes(
                      paymentMethodEnum.CreditCard
                    )
                  "
                >
                  <div class="checkbox-father mw-half">
                    <p class="text-color mt-3">Parcelas Cartão de Crédito</p>
                    <InputSelect
                      :items="getHowMany()"
                      v-model="
                        campaignPaymentMethodParcelamentCreditCard.parcelament
                      "
                      valueAttribute="quantidade"
                      textAttribute="name"
                      unselectedValue="null"
                      unselectedText="Parcelas"
                      @input="
                        showParcelamentMessage(
                          campaignPaymentMethodParcelamentCreditCard.parcelament
                        )
                      "
                    ></InputSelect>
                    <p class="text-color mt-3">Valor mínimo das Parcelas</p>
                    <InputMoney
                      v-model="campaignPaymentMethodParcelamentCreditCard.minimumParcelamentValue"
                      textLabel="Qual será o valor mínimo das parcelas"
                      invalidInputMessage="Preencha o valor mínimo das parcelas"
                      :precision="0"
                      @blur="
                        verifyMinimumParcelamentValue(
                          campaignPaymentMethodParcelamentCreditCard.minimumParcelamentValue,
                          campaignPaymentMethodParcelamentCreditCard.parcelament
                        )
                      "
                    />
                  </div>
                </v-flex>
                <v-flex
                  v-if="
                    campaign.paymentMethodList.includes(
                      paymentMethodEnum.Ticket
                    )
                  "
                >
                  <div class="checkbox-father mw-half">
                    <p class="text-color mt-3">Parcelas Boleto</p>
                    <InputSelect
                      :items="getHowMany()"
                      v-model="
                        campaignPaymentMethodParcelamentTicket.parcelament
                      "
                      valueAttribute="quantidade"
                      textAttribute="name"
                      unselectedValue="null"
                      unselectedText="Parcelas"
                      @input="
                        showParcelamentMessage(
                          campaignPaymentMethodParcelamentTicket.parcelament
                        )
                      "
                    ></InputSelect>
                    <p class="text-color mt-3">Valor mínimo das Parcelas</p>
                    <InputMoney
                      v-model="
                        campaignPaymentMethodParcelamentTicket.minimumParcelamentValue
                      "
                      textLabel="Qual será o valor mínimo das parcelas"
                      invalidInputMessage="Preencha o valor mínimo das parcelas"
                      :precision="0"
                      @blur="
                        verifyMinimumParcelamentValue(
                          campaignPaymentMethodParcelamentTicket.minimumParcelamentValue,
                          campaignPaymentMethodParcelamentTicket.parcelament
                        )
                      "
                    />
                  </div>
                </v-flex>
                <v-flex
                  v-if="
                    campaign.paymentMethodList.includes(paymentMethodEnum.Pix)
                  "
                >
                  <div class="checkbox-father mw-half">
                    <p class="text-color mt-3">Parcelas Pix</p>
                    <InputSelect
                      :items="getHowMany()"
                      v-model="campaignPaymentMethodParcelamentPix.parcelament"
                      valueAttribute="quantidade"
                      textAttribute="name"
                      unselectedValue="null"
                      unselectedText="Parcelas"
                      @input="
                        showParcelamentMessage(
                          campaignPaymentMethodParcelamentPix.parcelament
                        )
                      "
                    ></InputSelect>
                    <p class="text-color mt-3">Valor mínimo das Parcelas</p>
                    <InputMoney
                      v-model="
                        campaignPaymentMethodParcelamentPix.minimumParcelamentValue
                      "
                      textLabel="Qual será o valor mínimo das parcelas"
                      invalidInputMessage="Preencha o valor mínimo das parcelas"
                      :precision="0"
                      @blur="
                        verifyMinimumParcelamentValue(
                          campaignPaymentMethodParcelamentPix.minimumParcelamentValue,
                          campaignPaymentMethodParcelamentPix.parcelament
                        )
                      "
                    />
                  </div>
                </v-flex>
              </v-layout>
              <!-- </template> -->
              <BorderSeparation />
              <div class="head mt-0">
                <h4>Meios de Pagamento - Público Interno</h4>
              </div>
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="text-color">
                    Selecione abaixo as empresas que poderão participar da
                    campanha.
                    <br />Se desejar, você pode alterar o meio de pagamento e a
                    recorrência da doação para cada uma delas.
                  </p>
                </v-flex>
                <v-flex xs12>
                  <ul class="pagto-list">
                    <li
                      :class="{ active: company.selected }"
                      v-for="company in companyList"
                      :key="company.id"
                    >
                      <v-layout>
                        <v-flex shrink pr-3>
                          <v-checkbox
                            class="ma-0 pa-0"
                            v-model="company.selected"
                            @change="
                              company.choosingPaymentMethods =
                                company.choosingPaymentMethods &&
                                company.selected
                            "
                          ></v-checkbox>
                        </v-flex>
                        <v-flex>
                          <h4>{{ company.entity.name }}</h4>
                          <h6>{{ company.entity.cnpj }}</h6>
                          <input
                            type="checkbox"
                            :id="'e1' + company.id"
                            :disabled="company.selected ? false : 'disabled'"
                            v-model="company.choosingPaymentMethods"
                          />
                          <label :for="'e1' + company.id" class="openClose">
                            Meio de Pagamento
                            <i class="ml-2"></i>
                          </label>
                          <div class="pagto">
                            <hr class="sm mb-2 mt-3" />
                            <InputCheckBoxList
                              :list="paymentMethodList"
                              v-model="company.campaignCompanyPaymentMethodList"
                              labelAttribute="name"
                              valueAttribute="id"
                              :columns="3"
                            />
                            <template
                              v-if="
                                company.campaignCompanyPaymentMethodList.includes(
                                  paymentMethodEnum.PayrollDeduction
                                )
                              "
                            >
                              <div>
                                <BorderSeparation />
                                <h4>Débito em folha</h4>
                                <p class="text-color mt-3">
                                  Escolha as formas de pagamento do débito em
                                  folha.
                                </p>
                                <v-flex>
                                  <v-radio-group
                                    v-model="company.payrollDeductionInput"
                                    @change="
                                      changePayrollDeductionInput(company)
                                    "
                                  >
                                    <v-radio
                                      label="Com parcelamento ou recorrência"
                                      :value="true"
                                    ></v-radio>
                                    <v-radio
                                      label="Sem parcelamento"
                                      :value="false"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-flex>
                                <div :id="payrollDeductionInputId">
                                  <v-flex
                                    form-grid
                                    v-if="checkPayrollDeductionInput(company)"
                                  >
                                    <v-layout row wrap>
                                      <v-flex xs5 md2>
                                        <InputSelect
                                          :items="howManyOptions(company)"
                                          v-model="howManySelected"
                                          valueAttribute="quantidade"
                                          textAttribute="name"
                                          unselectedValue="null"
                                          unselectedText="Parcelas"
                                        ></InputSelect>
                                      </v-flex>
                                      <v-flex xs5 md3>
                                        <InputText
                                          type="text"
                                          v-model="descriptionParcelament"
                                          place-holder="Descrição"
                                        />
                                      </v-flex>
                                      <div
                                        class="d-flex align-center ml-3 xs2 md1"
                                        @click="addParcelament(company)"
                                      >
                                        <Icon
                                          name="fal fa-plus"
                                          title="Adicionar"
                                          size="16"
                                          colorFont="#02acb4"
                                        />
                                      </div>
                                    </v-layout>
                                    <v-layout>
                                      <v-flex x12>
                                        <div
                                          v-for="parcelament in company.parcelaments"
                                          :key="parcelament.description"
                                          class="display-flex align-center"
                                        >
                                          <strong
                                            >{{ parcelament.howMany }}x</strong
                                          >
                                          <span class="mx-1">{{
                                            parcelament.description
                                          }}</span>
                                          <a
                                            @click="
                                              deleteParcelament(
                                                company,
                                                parcelament
                                              )
                                            "
                                          >
                                            <Icon
                                              name="fal fa-times"
                                              title="remover"
                                              size="16"
                                              colorFont="#02acb4"
                                              class="ml-2"
                                            />
                                          </a>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </div>
                                <BorderSeparation />
                              </div>
                            </template>
                            <template>
                              <p class="text-color mt-3">
                                Recorrências de doação permitidas -
                                <small>Até 3 opções</small>
                              </p>
                              <InputCheckBoxListWithMoneyInput
                                :list="donationRecurrencePeriodList"
                                v-model="
                                  company.companyDonationRecurrencePeriod
                                "
                                labelAttribute="name"
                                valueAttribute="id"
                                :numberOfElementsPerColumn="[3, 2, 2]"
                                :columns="3"
                                :listSizeLimit="3"
                              />
                            </template>
                          </div>
                        </v-flex>
                      </v-layout>
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
              <BorderSeparation />
              <v-layout>
                <v-flex xs6>
                  <div class="head mt-0">
                    <h4>Projetos</h4>
                  </div>
                </v-flex>
                <v-flex xs6>
                  <NewProject
                    @checkCampaignType="hasCampaignTypeId"
                    ref="projectSection"
                    :institutionList="institutionList"
                    v-model="selectedProjects"
                    :campaignTypeId="campaignTypeId"
                    @input="orderSelectedProjects()"
                  ></NewProject>
                </v-flex>
              </v-layout>
              <v-layout column>
                <div
                  class="list-scroll m-height-170 mt-3"
                  v-for="(project, index) in selectedProjects"
                  :key="project.id"
                >
                  <div class="check-project">
                    <ProjectBox
                      :showCompanySelect="true"
                      :project="project"
                      :institutionList="institutionList"
                      :companyList="companyList"
                      :canDelete="true"
                      @delete="openConfirmRemoveProjectModal(index)"
                    ></ProjectBox>
                  </div>
                </div>
              </v-layout>
            </div>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
    <div class="footer-step">
      <v-btn
        flat
        round
        color="white"
        class="btn-primary flex-inherit px-5 ml-auto"
        large
        @click="save"
        >Salvar</v-btn
      >
    </div>
    <AucAlert
      ref="confirmCpfIsNotRequired"
      title="Você tem certeza que deseja tornar o CPF não requerido? Para isso certifique-se de utilizar como meio de pagamento Cartão de Crédito com checkout transparente e recorrência única."
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="campaign.cpfIsRequired = false"
    />
    <AucAlert
      ref="removeProjectAlert"
      title="Você tem certeza que deseja remover esse projeto da campanha?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="removeProject()"
    />
    <AucAlert
      ref="changeCampaignTypeAlert"
      title="Os projetos precisarão ser escolhidos novamente, você tem certeza que deseja continuar?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="changeCampaignType()"
      @cancel="cancelChangeCampaignType()"
    />
    <AucAlert
      ref="selectParcelamentAlert"
      :title="parcelamentAlertText()"
      confirmButtonText="Ok"
    />
  </div>
</template>

<script>
import CampaignService from "@/scripts/services/campaign.service.js";
import FileService from "@/scripts/services/file.service.js";
import InstitutionService from "@/scripts/services/institution.service.js";
import ProjectService from "@/scripts/services/project.service.js";
import CompanyService from "@/scripts/services/company.service.js";

import Campaign from "@/scripts/models/campaign.model.js";
import Parcelament from "@/scripts/models/parcelament.model.js";
import File from "@/scripts/models/file.model.js";
import TicketConfig from "@/scripts/models/ticketConfig.model.js";

import {
  CampaignTypes,
  CampaignTypesList,
} from "@/scripts/models/enums/campaignTypes.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import CampaignConsentTerm from "@/scripts/models/campaignConsentTerm.model";
import Permissions from "@/scripts/models/enums/permissions.enum";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

import FooterStep from "@/components/FooterStep.vue";
import AucAlert from "@/components/AucAlert.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProjectBox from "@/components/ProjectBox.vue";
import NewProject from "@/components/NewProject.vue";
import HelpBox from "@/components/HelpBox.vue";
import CommonHelper from "@/scripts/helpers/common.helper";

import { mapGetters } from "vuex";
import PreviewShare from "../../../components/PreviewShare";
import { getDate, max } from "date-fns";
import InputText from "../../../components/input/InputText.vue";

export default {
  extends: ValidatableComponent,
  components: {
    PreviewShare,
    HelpBox,
    FooterStep,
    AucAlert,
    Breadcrumb,
    ProjectBox,
    NewProject,
    InputText,
  },
  props: ["pageOptions"],
  data() {
    return {
      companyId: 0,
      companyList: [],
      institutionService: new InstitutionService(),
      campaignService: new CampaignService(),
      projectService: new ProjectService(),
      companyService: new CompanyService(),
      campaign: new Campaign(),
      fileService: new FileService(),
      institutionList: [],
      paymentMethodList: [],
      paymentMethodProviderList: [],
      donationRecurrencePeriodList: [],
      uniqueRecurrencePeriodList: [],
      projectsAlreadyLoaded: true,
      selectedProjectIndex: 0,
      helpBoxText: HelpBoxText.CampaignEdit,
      selectedProjects: [],
      campaignId: 0,
      initialFinalDates: [],
      paymentMethodEnum: null,
      howManySelected: null,
      paymentMethodProviderSelected: null,
      descriptionParcelament: "",
      ticketConfigDefault: true,
      ticketConfigId: null,
      ticketConfigs: [],
      permissions: Permissions,
      campaignTypeId: 0,
      campaignTypeBefore: 0,
      campaignTypeAfter: 0,
      CampaignTypes: CampaignTypes,
      CampaignTypesList: CampaignTypesList,
      checkboxListSelected: [],
      checkboxListSelectedSimplified: [],      
      checkboxListInformModal: [
        {
          id: 1,
          name: "Informar empresa",
        },
        {
          id: 2,
          name: "Informar departamento",
        },
        {
          id: 3,
          name: "Informar endereço",
        },
        {
          id: 4,
          name: "Informar estado",
        },
        {
          id: 5,
          name: "Telefone",
        },
        {
          id: 9,
          name: "Telefone alternativo",
        },
        {
          id: 6,
          name: "Gênero",
        },
        {
          id: 7,
          name: "Data de Nascimento",
        },
        {
          id: 8,
          name: "Código de indicação",
        },
      ],
      checkboxListInformModalSimplified: [
        {
          id: 1,
          name: "Informar empresa",
        },
        {
          id: 2,
          name: "Informar departamento",
        },
        {
          id: 3,
          name: "Informar endereço",
        },
        {
          id: 4,
          name: "Informar estado",
        },
        {
          id: 5,
          name: "Telefone",
        },
        {
          id: 9,
          name: "Telefone alternativo",
        },
        {
          id: 6,
          name: "Gênero",
        },
        {
          id: 7,
          name: "Data de Nascimento",
        },
        {
          id: 8,
          name: "Código de Membro",
        },
      ],
      howMany: [
        {
          quantidade: 0,
          name: "1x Sem parcelamento",
        },
        {
          quantidade: 1,
          name: "1x",
        },
        {
          quantidade: 2,
          name: "2x",
        },
        {
          quantidade: 3,
          name: "3x",
        },
        {
          quantidade: 4,
          name: "4x",
        },
        {
          quantidade: 5,
          name: "5x",
        },
        {
          quantidade: 6,
          name: "6x",
        },
        {
          quantidade: 7,
          name: "7x",
        },
        {
          quantidade: 8,
          name: "8x",
        },
        {
          quantidade: 9,
          name: "9x",
        },
        {
          quantidade: 10,
          name: "10x",
        },
        {
          quantidade: 11,
          name: "11x",
        },
        {
          quantidade: 12,
          name: "12x",
        },
      ],
      breadcrumbLevels: [
        // { pathName: Routes.app.DashboardPF, text: "Início" },
        { text: "Início" },
        { text: "Campanha" },
        { text: "Edição" },
      ],
      receiver: [
        { id: 1, text: "2 dias" },
        { id: 2, text: "30 dias" },
      ],
      campaignPaymentMethodParcelamentCreditCard: {
        campaignId: 0,
        paymentMethodId: 0,
        parcelament: 0,
        minimumParcelamentValue: 0,
      },
      campaignPaymentMethodParcelamentTicket: {
        campaignId: 0,
        paymentMethodId: 0,
        parcelament: 0,
        minimumParcelamentValue: 0,
      },
      campaignPaymentMethodParcelamentPix: {
        campaignId: 0,
        paymentMethodId: 0,
        parcelament: 0,
        minimumParcelamentValue: 0,
      },
      apiRegistrationList: [],
      apiRegistration: {
        cpf: "",
        name: "",
        email: "",
        registration: "",
        departament: "",
        cnpj: "",
        url: "",
      },
      payrollDeductionInputId: 0,
    };
  },
  created() {
    this.pageOptions.changeClass("footer");
    this.validationMethod = this.validate;
    this.paymentMethodEnum = PaymentMethod;
    this.campaign.campaignInputInformation = {};
    this.$emit("changeLevels", this.breadcrumbLevels);
    if (this.$route.query.campaignId) {
      this.campaignId = this.$route.query.campaignId;
      this.campaignService.findById(
        this.campaignService_findBYIdCallback,
        this.campaignId
      );
    } else if (this.$route.query.companyId) {
      this.projectsAlreadyLoaded = false;
      this.campaign.companyId = this.$route.query.companyId;
      this.campaign.company.id = this.campaign.companyId;
      this.getCompaniesFromSameGroup();
    }
    this.campaignService.listAllPaymentMethod(
      this.paymentMethodProviderCallback
    );
    this.campaignService.listAllPaymentMethod(
      this.listAllPaymentMethodCallback
    );
    this.campaignService.listAllDonationRecurrencePeriod(
      this.listAllDonationRecurrencePeriodCallback
    );
    this.institutionService.listAll(this.institutionService_listAllCallback);
    this.crea;

    this.campaignService.listAllTicketConfig(this.ticketConfigCallback);
    // this.campaignService.listAllPaymentMethodProvider(
    //   this.paymentMethodProviderCallback
    // );
  },
  watch: {
    initialFinalDates(val) {
      if (val && val instanceof Array && val.length == 2) {
        this.campaign.initialDate = val[0];
        this.campaign.finalDate = val[1];
      } else {
        this.campaign.initialDate = null;
        this.campaign.finalDate = null;
      }
    },
    "company.parcelaments"() {},
    ticketConfigDefault: function (val) {
      if (val) {
        this.ticketConfigId = null;
      }
    },
    ticketConfigId: function (val) {
      if (val == null) {
        this.campaign.ticketConfig = null;
      } else {
        this.campaign.ticketConfig = this.ticketConfigs.filter(function (item) {
          if (item.id == val) {
            return item;
          }
        })[0];
      }
    },
    campaignTypeId: function (after, before) {
      if (
        this.selectedProjects &&
        this.selectedProjects.length > 0 &&
        before !== 0 &&
        after !== this.campaignTypeBefore
      ) {
        this.campaignTypeBefore = parseInt(before);
        this.campaignTypeAfter = parseInt(after);
        this.$refs.changeCampaignTypeAlert.open();
      } else {
        this.campaign.campaignTypeId = parseInt(after);
        this.$refs.projectSection.refreshList(after);
      }
    },
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    publicPaymentMethodList() {
      let list = [];
      if (this.paymentMethodList && this.paymentMethodList.length > 0) {
        for (let i = 0; i < this.paymentMethodList.length; i++) {
          if (this.paymentMethodList[i].id != PaymentMethod.AccountDebit) {
            list.push(this.paymentMethodList[i]);
          }
          //Somente o instituto airton senna possui débito em conta
          else if (this.campaignId == "53") {
            list.push(this.paymentMethodList[i]);
          }
        }
      }
      return list;
    },
  },
  methods: {
    checkPayrollDeductionInput(company) {
      return company.payrollDeductionInput;
    },
    changePayrollDeductionInput() {
      this.payrollDeductionInputId++;
    },
    loadPayrollDeductionInput(company) {
      company.payrollDeductionInput = true;
      if (company.parcelaments && company.parcelaments.length > 0) {
        if (
          company.parcelaments[0].campaignCompanyPaymentMethod &&
          company.parcelaments[0].campaignCompanyPaymentMethod
            .donationRecurrencePeriodId >= 2
        ) {
          company.payrollDeductionInput = false;
        }
      }
    },
    setCampaignApi() {
      this.campaign.campaignApiList = this.apiRegistrationList;
    },
    removeApiRegistration(index) {
      if (this.apiRegistrationList.length > 0) {
        this.apiRegistrationList.splice(index, 1);
      }
    },
    addApiRegistration() {
      if (
        !this.apiRegistration.cpf ||
        !this.apiRegistration.url ||
        !this.apiRegistration.apiName
      ) {
        return;
      }
      this.apiRegistration.campaignId = this.campaign.id;

      var item = { ...this.apiRegistration };

      this.apiRegistrationList.push(item);

      this.apiRegistration = {};
    },
    setCampaignPaymentMethodParcelament() {
      if (
        !this.verifyMinimumParcelamentValue(
          this.campaign.minimumParcelamentValue,
          this.campaignPaymentMethodParcelamentCreditCard.parcelament
        ) ||
        !this.verifyMinimumParcelamentValue(
          this.campaign.minimumParcelamentValue,
          this.campaignPaymentMethodParcelamentTicket.parcelament
        ) ||
        !this.verifyMinimumParcelamentValue(
          this.campaign.minimumParcelamentValue,
          this.campaignPaymentMethodParcelamentPix.parcelament
        )
      ) {
        return false;
      }

      (this.campaign.campaignPaymentMethodParcelamentList = []),
        (this.campaignPaymentMethodParcelamentCreditCard.campaignId =
          this.campaignPaymentMethodParcelamentTicket.campaignId =
          this.campaignPaymentMethodParcelamentPix.campaignId =
            this.campaign.id);

      this.campaignPaymentMethodParcelamentCreditCard.paymentMethodId =
        this.paymentMethodEnum.CreditCard;
      this.campaign.campaignPaymentMethodParcelamentList.push(
        this.campaignPaymentMethodParcelamentCreditCard
      );

      this.campaignPaymentMethodParcelamentTicket.paymentMethodId =
        this.paymentMethodEnum.Ticket;
      this.campaign.campaignPaymentMethodParcelamentList.push(
        this.campaignPaymentMethodParcelamentTicket
      );

      this.campaignPaymentMethodParcelamentPix.paymentMethodId =
        this.paymentMethodEnum.Pix;
      this.campaign.campaignPaymentMethodParcelamentList.push(
        this.campaignPaymentMethodParcelamentPix
      );
    },
    parcelamentAlertText() {
      var currentYear = new Date().getFullYear();
      return `Importante! Somente os valores pagos até os dias 28/12/${currentYear} via PIX, 26/12/${currentYear} via BOLETO e CARTÃO DE CRÉDITO poderão ser deduzidos em seu IRPF ${
        currentYear + 1
      } (exercício ${currentYear}).
              Caso queira que todo o valor doado seja deduzido no IR do próximo ano, por favor, efetue todos os pagamentos até as datas informadas acima.
              As informações referentes aos valores que devem ser considerados para abatimento do IRPF ${
                currentYear + 1
              } (exercício ${currentYear}), serão enviadas no Informe de Doações.`;
    },
    showParcelamentMessage(creditCardParcelament) {
      if (
        creditCardParcelament > 1 &&
        this.campaign.campaignTypeId == CampaignTypes.Incentivada
      ) {
        this.$refs.selectParcelamentAlert.open();
      }
    },
    verifyMinimumParcelamentValue(value, parcelament) {
      if (
        parcelament > 0 &&
        value * parcelament < this.campaign.minimumDonationValue
      ) {
        CommonHelper.swal(
          "",
          "O valor mínimo do parcelamento multiplicado pelo valor máximo de parcelas, não pode ser menor que o valor mínimo da doação.",
          "error"
        );
        return false;
      }
      return true;
    },
    SetAnnonymousDonationToUnchecked(value) {
      if (!value) {
        this.checkboxListSelectedSimplified = [];
      }
    },
    hasCampaignTypeId() {
      if (this.campaignTypeId == 0) {
        this.$refs.campaignTypeInput.focus();
      }
    },
    changeToFalseCpfIsRequired() {
      if (!this.campaign.cpfIsRequired) {
        this.campaign.cpfIsRequired = true;
        this.$refs.confirmCpfIsNotRequired.open();
      }
    },
    changeCampaignType() {
      this.campaign.campaignTypeId = parseInt(this.campaignTypeAfter);
      this.$refs.projectSection.refreshList(this.campaign.campaignTypeId);
      this.removeSelectedProjectsBasedOnType(this.campaign.campaignTypeId);
    },
    cancelChangeCampaignType() {
      this.$set(this, "campaignTypeId", parseInt(this.campaignTypeBefore));
    },
    howManyOptions(company) {
      let options = [...this.howMany];
      options[0].quantidade = 1;
      options.unshift({
        quantidade: 0,
        name: "0x Sem parcelamento",
      });

      if (company == null || company.parcelaments == null) return options;

      company.parcelaments.forEach((parcelament) => {
        let element = options.findIndex((el) => {
          return el.quantidade == parcelament.howMany;
        });
        options.splice(element, 1);
      });

      return options;
    },
    getHowMany() {
      var parcelamentList = [];
      var maxParcelament = 12;
      if (!this.campaign.donationForTheNextYear) {
        maxParcelament = 12 - (new Date().getMonth() + 1);
      }

      this.howMany.forEach((parcelament) => {
        if (
          parcelament.quantidade != 1 &&
          parcelament.quantidade <= maxParcelament
        ) {
          parcelamentList.push(parcelament);
        }
      });
      return parcelamentList;
    },
    addParcelament(company) {
      if (this.howManySelected == null || this.descriptionParcelament == "")
        return;
      let parcelament = new Parcelament();
      parcelament.howMany = this.howManySelected;
      parcelament.description = this.descriptionParcelament;
      this.howManySelected = null;
      this.descriptionParcelament = "";
      if (company.parcelaments == null) company.parcelaments = [];
      company.parcelaments.push(parcelament);
      this.orderPacelaments(company);
    },
    orderPacelaments(company) {
      company.parcelaments.sort(function (b, a) {
        return b.howMany - a.howMany;
      });
    },
    deleteParcelament(company, parcelament) {
      let element = company.parcelaments.findIndex((el) => {
        return el.howMany == parcelament.howMany;
      });
      company.parcelaments.splice(element, 1);
    },
    getCompaniesFromSameGroup() {
      this.companyService
        .getCampaignCompaniesFromSameGroup(
          this.campaign.companyId,
          this.campaign.id
        )
        .then(this.companyService_getCampaignCompaniesFromSameGroupCallback);
    },
    ticketConfigCallback(data) {
      this.ticketConfigs = data;
      data.forEach(function (e, i) {
        e.description = e.eCommerceCode;
        if (e.id == 1) {
          e.description = e.eCommerceCode + " ( padrão )";
        }
      });
    },
    companyService_getCampaignCompaniesFromSameGroupCallback(data) {
      this.companyList = data;
      if (this.companyList) {
        for (let i = 0; i < this.companyList.length; i++) {
          this.$set(this.companyList[i], "choosingPaymentMethods", false);
          if (
            this.companyList[i].campaignCompanyPaymentMethodList != null &&
            this.companyList[i].campaignCompanyPaymentMethodList instanceof
              Array &&
            this.companyList[i].campaignCompanyPaymentMethodList.length > 0
          ) {
            this.$set(this.companyList[i], "selected", true);
          } else {
            this.$set(this.companyList[i], "selected", false);
          }

          this.loadPayrollDeductionInput(this.companyList[i]);
        }
      }
    },
    loadCampaignConsentTerms() {
      this.campaignService.listConsentTermsByCampaignId(
        this.campaign.id,
        (data) => {
          this.campaign.campaignConsentTerms = data;
        }
      );
    },
    campaignService_findBYIdCallback(data) {
      if (
        data.campaignPaymentMethodParcelamentList &&
        data.campaignPaymentMethodParcelamentList.length > 0
      ) {
        data.campaignPaymentMethodParcelamentList.forEach((element) => {
          if (element.paymentMethodId == this.paymentMethodEnum.CreditCard) {
            this.campaignPaymentMethodParcelamentCreditCard = element;
          }
          if (element.paymentMethodId == this.paymentMethodEnum.Ticket) {
            this.campaignPaymentMethodParcelamentTicket = element;
          }
          if (element.paymentMethodId == this.paymentMethodEnum.Pix) {
            this.campaignPaymentMethodParcelamentPix = element;
          }
        });
      }

      if (data.donationForTheNextYear == null) {
        data.donationForTheNextYear = true;
      }

      if (!data.campaignInputInformation) {
        data.campaignInputInformation = {};
      }

      if (!data.minimumParcelamentValue) {
        data.minimumParcelamentValue = data.minimumDonationValue;
      }

      if (data.donateToMoreThanOneProduct == null) {
        data.donateToMoreThanOneProduct = true;
      }

      if (data.campaignApiList != null) {
        this.apiRegistrationList = data.campaignApiList;
      }

      this.campaign = data;
      this.campaignTypeId = this.campaign.campaignTypeId;
      this.mountCheckboxListInform(data.campaignInputInformation);
      this.getCompaniesFromSameGroup();
      this.loadCampaignConsentTerms();
      if (this.campaign.file == null) this.campaign.file = new File();
      else {
        this.fileService
          .render(this.campaign.file.id)
          .then(this.renderCallback);
      }

      if (this.campaign.faviconFile == null)
        this.campaign.faviconFile = new File();
      else {
        this.fileService
          .render(this.campaign.faviconFile.id)
          .then(this.renderShareCallback);
      }

      this.initialFinalDates = [
        this.campaign.initialDate,
        this.campaign.finalDate,
      ];

      let projectIds = [];
      for (var key in this.campaign.projectList) {
        projectIds.push(parseInt(key));
      }
      this.projectService.findByIds(
        this.campaignService_findByIdsCallback,
        projectIds
      );

      this.ticketConfigId = this.campaign.ticketConfig.id;
      this.ticketConfigDefault = !(this.ticketConfigId > 1);
    },
    renderCallback(data) {
      this.$refs.preview.style.backgroundImage = "url(" + data + ")";
    },
    renderShareCallback(data) {
      const path = data.replace(
        "image/upload",
        "image/upload/w_150,h_150,c_pad,b_auto:predominant/"
      );
      this.$refs.share.style.backgroundImage = "url(" + data + ")";
      this.$refs.favIconPreview1.style.backgroundImage = "url(" + path + ")";
      this.$refs.favIconPreview2.style.backgroundImage = "url(" + path + ")";
    },
    campaignService_findByIdsCallback(data) {
      this.projectsAlreadyLoaded = false;
      for (var key in this.campaign.projectList) {
        var projectId = parseInt(key);
        data.forEach(
          function (element) {
            if (element.id == projectId) {
              element.selectedCompanies = this.campaign.projectList[key];
            }
          }.bind(this)
        );
      }
      this.selectedProjects = data;
      this.orderSelectedProjects();
    },
    institutionService_listAllCallback(data) {
      this.institutionList = data;
    },
    listAllPaymentMethodCallback(data) {
      this.paymentMethodList = data;
    },
    paymentMethodProviderCallback(data) {
      this.paymentMethodProviderList = data;
    },
    getProvidersByPaymentMethod(paymentMethodId) {
      if (
        this.paymentMethodProviderList == undefined ||
        this.paymentMethodProviderList == null
      )
        return [];
      let ret = [];
      this.paymentMethodProviderList.forEach((el) => {
        if (el.id == paymentMethodId) {
          el.paymentMethodPaymentMethodProviders.forEach((pm) => {
            ret.push(pm.paymentMethodProvider);
          });
        }
      });
      return ret;
    },
    listAllDonationRecurrencePeriodCallback(data) {
      this.donationRecurrencePeriodList = data;
    },
    fileChanged(event) {
      var file = event.target.files[0];
      this.campaign.file.name = file.name;
      let reader = new FileReader();
      reader.onload = function () {
        this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
        this.campaign.file.path = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    },
    faviconFileChanged(event) {
      var file = event.target.files[0];
      //tamanho maximo da imagem de 500Kb
      if (file.size > 500 * 1024) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "A imagem deve ter no máximo 500Kb de tamanho"
        );
      } else {
        this.campaign.faviconFile.name = file.name;
        let reader = new FileReader();
        reader.onload = function () {
          this.$refs.share.style.backgroundImage = "url(" + reader.result + ")";
          this.$refs.favIconPreview1.style.backgroundImage =
            "url(" + reader.result + ")";
          this.$refs.favIconPreview1.style.backgroundSize = "contain";
          this.$refs.favIconPreview2.style.backgroundImage =
            "url(" + reader.result + ")";
          this.$refs.favIconPreview2.style.backgroundSize = "contain";
          this.campaign.faviconFile.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    openConfirmRemoveProjectModal(index) {
      this.selectedProjectIndex = index;
      this.$refs.removeProjectAlert.open();
    },
    removeSelectedProjectsBasedOnType(type) {
      this.selectedProjects = this.selectedProjects.filter(
        (pj) => pj.projectTypeId == type
      );
    },
    removeProject() {
      this.selectedProjects.splice(this.selectedProjectIndex, 1);
    },
    buildPaymentMethodListPerCompany() {
      this.campaign.campaignCompanyPaymentMethodList = [];
      if (this.companyList) {
        for (let i = 0; i < this.companyList.length; i++) {
          let company = this.companyList[i];
          if (company.selected) {
            for (
              let j = 0;
              j < company.campaignCompanyPaymentMethodList.length;
              j++
            ) {
              let pm = company.campaignCompanyPaymentMethodList[j];
              let ccpm = {
                companyId: company.id,
                paymentMethodId: pm,
                campaignId: this.campaign.id,
              };
              if (
                pm == PaymentMethod.PayrollDeduction &&
                company.parcelaments != null
              ) {
                ccpm.parcelaments = company.parcelaments;

                if (!company.payrollDeductionInput) {
                  ccpm.donationRecurrencePeriodId = 2;
                } else {
                  ccpm.donationRecurrencePeriodId = 1;
                }
              }
              this.campaign.campaignCompanyPaymentMethodList.push(ccpm);
            }
          }
        }
      }
    },
    buildDonationRecurrencePeriodListPerCompany() {
      this.campaign.campaignCompanyDonationRecurrencePeriodList = [];
      if (this.companyList) {
        for (let i = 0; i < this.companyList.length; i++) {
          let company = this.companyList[i];
          if (company.selected) {
            Object.keys(company.companyDonationRecurrencePeriod).forEach(
              (p) => {
                let ccdr = {
                  donationRecurrencePeriodId: p,
                  campaignId: this.campaign.id,
                  companyId: company.id,
                  minimumDonationValue:
                    company.companyDonationRecurrencePeriod[p],
                };

                this.campaign.campaignCompanyDonationRecurrencePeriodList.push(
                  ccdr
                );
              }
            );
          }
        }
      }
    },
    buildDonationRecurrencePeriodList() {
      this.campaign.campaignDonationRecurrencePeriodList = [];

      Object.keys(this.campaign.donationRecurrencePeriodList).forEach((p) => {
        let drp = {
          donationRecurrencePeriodId: p,
          campaignId: this.campaign.id,
          minimumDonationValue: this.campaign.donationRecurrencePeriodList[p],
        };

        this.campaign.campaignDonationRecurrencePeriodList.push(drp);
      });
    },
    buildDonationRecurrencePeriodListPerCompanyForTickets() {
      this.campaign.campaignCompanyDonationRecurrencePeriodList = [];
      if (this.companyList) {
        for (let i = 0; i < this.companyList.length; i++) {
          let company = this.companyList[i];
          if (company.selected) {
            let ccdr = {
              donationRecurrencePeriodId: 1,
              campaignId: this.campaign.id,
              companyId: company.id,
              minimumDonationValue: 0,
            };

            this.campaign.campaignCompanyDonationRecurrencePeriodList.push(
              ccdr
            );
          }
        }
      }
    },
    buildDonationRecurrencePeriodListForTickets() {
      this.campaign.campaignDonationRecurrencePeriodList = [];
      let drp = {
        donationRecurrencePeriodId: 1,
        campaignId: this.campaign.id,
        minimumDonationValue: 0,
      };
      this.campaign.campaignDonationRecurrencePeriodList.push(drp);
    },
    save() {
      this.campaign.receiver = parseInt(this.campaign.receiver);
      this.campaign.simulatorPercentage = parseInt(this.campaign.simulatorPercentage)
      if (
        this.campaign.campaignPaymentMethodProvider
          .creditCardPaymentMethodProviderId == null ||
        this.campaign.campaignPaymentMethodProvider
          .creditCardPaymentMethodProviderId == 0
      ) {
        CommonHelper.swal(
          "",
          "Escolha um provedor de pagamento para cartão",
          "error"
        );
        return false;
      }

      if (
        this.campaign.campaignPaymentMethodProvider
          .ticketPaymentMethodProviderId == null ||
        this.campaign.campaignPaymentMethodProvider
          .ticketPaymentMethodProviderId == 0
      ) {
        CommonHelper.swal(
          "",
          "Escolha um provedor de pagamento para boleto",
          "error"
        );
        return false;
      }

      this.setCampaignPaymentMethodParcelament();
      this.setCampaignApi();

      this.campaign.projectList = {};
      for (let i = 0; i < this.selectedProjects.length; i++) {
        var item = [];
        if (this.selectedProjects[i].selectedCompanies) {
          item = this.selectedProjects[i].selectedCompanies.filter(
            function (id) {
              var obj = this.companyList.filter(function (el) {
                return el.id == id && el.selected;
              });
              return obj && obj.length > 0;
            }.bind(this)
          );
        }

        this.campaign.projectList[this.selectedProjects[i].id] = item;
      }
      this.buildPaymentMethodListPerCompany();
      if (this.campaign.campaignTypeId == CampaignTypes.Ticket) {
        // this.campaign.minimumDonationValue = null;
        // this.buildDonationRecurrencePeriodListForTickets();
        // this.buildDonationRecurrencePeriodListPerCompanyForTickets();
      }
      this.buildDonationRecurrencePeriodList();
      this.buildDonationRecurrencePeriodListPerCompany();
      this.campaignService.save(this.saveCallback, this.campaign);
    },
    saveCallback(data) {
      if (data)
        this.$router.push({
          name: Routes.app.CampaignList,
        });
    },
    orderSelectedProjects() {
      this.selectedProjects = this.selectedProjects.sort((a, b) => a.id - b.id);
    },
    validate(performMethods) {
      if (this.campaign.file == null || this.campaign.file.path == "") {
        CommonHelper.swal("", "Escolha uma imagem para a campanha", "error");
        return false;
      }

      let valid = this.validateComponents(performMethods);

      if (this.campaign.campaignTypeId != CampaignTypes.Ticket) {
        for (let i = 0; i < this.companyList.length; i++) {
          let company = this.companyList[i];
          if (company.selected) {
            if (
              Object.keys(company.companyDonationRecurrencePeriod).length ==
                0 ||
              company.campaignCompanyPaymentMethodList.length == 0
              //company.campaignCompanyDonationRecurrencePeriodList.length == 0 ||
              //company.campaignCompanyPaymentMethodList.length == 0
            ) {
              CommonHelper.swal(
                "",
                "Você não preencheu todos os campos",
                "error"
              );
              return false;
            }
          }
        }
      }

      if (!this.selectedProjects || this.selectedProjects.length == 0) {
        CommonHelper.swal("", "Selecione pelo menos um projeto", "error");
        return false;
      }

      return valid;
    },
    changeCheckboxListInform() {
      this.campaign.informCompany = false;
      this.campaign.informDepartament = false;
      this.campaign.informAddress = false;
      this.campaign.informState = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformCompany = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformDepartment = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformAddress = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformState = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformPhone = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformGender = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformBirthDate = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformMemberCode = false;
      this.campaign.campaignInputInformation.ModalConfirmationInformAlternativePhone = false;
      this.checkboxListSelected.forEach((el) => {
        switch (el) {
          case 1:
            this.campaign.campaignInputInformation.ModalConfirmationInformCompany = true;
            break;
          case 2:
            this.campaign.campaignInputInformation.ModalConfirmationInformDepartment = true;
            break;
          case 3:
            this.campaign.campaignInputInformation.ModalConfirmationInformAddress = true;
            break;
          case 4:
            this.campaign.campaignInputInformation.ModalConfirmationInformState = true;
            break;
          case 5:
            this.campaign.campaignInputInformation.ModalConfirmationInformPhone = true;
            break;
          case 6:
            this.campaign.campaignInputInformation.ModalConfirmationInformGender = true;
            break;
          case 7:
            this.campaign.campaignInputInformation.ModalConfirmationInformBirthDate = true;
            break;
          case 8:
            this.campaign.campaignInputInformation.ModalConfirmationInformMemberCode = true;
            break;
          case 9:
            this.campaign.campaignInputInformation.ModalConfirmationInformAlternativePhone = true;
            break;
        }
      });
    },
    changeCheckboxListInformSimplified() {
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformCompany = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformDepartment = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformAddress = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformState = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformPhone = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformGender = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformBirthDate = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformMemberCode = false;
      this.campaign.campaignInputInformation.SimplifiedCheckoutInformAlternativePhone = false;
      this.checkboxListSelectedSimplified.forEach((el) => {
        switch (el) {
          case 1:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformCompany = true;
            break;
          case 2:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformDepartment = true;
            break;
          case 3:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformAddress = true;
            break;
          case 4:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformState = true;
            break;
          case 5:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformPhone = true;
            break;
          case 6:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformGender = true;
            break;
          case 7:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformBirthDate = true;
            break;
          case 8:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformMemberCode = true;
            break;
          case 9:
            this.campaign.campaignInputInformation.SimplifiedCheckoutInformAlternativePhone = true;
            break;
        }
      });
    },
    mountCheckboxListInform(data) {
      var informInput = JSON.parse(JSON.stringify(data));

      let selected = [];
      let selectdSimplified = [];

      if (informInput.modalConfirmationInformCompany) selected.push(1);
      if (informInput.modalConfirmationInformDepartment) selected.push(2);
      if (informInput.modalConfirmationInformAddress) selected.push(3);
      if (informInput.modalConfirmationInformState) selected.push(4);
      if (informInput.modalConfirmationInformPhone) selected.push(5);
      if (informInput.modalConfirmationInformGender) selected.push(6);
      if (informInput.modalConfirmationInformBirthDate) selected.push(7);
      this.checkboxListSelected = selected;

      if (informInput.simplifiedCheckoutInformCompany)
        selectdSimplified.push(1);
      if (informInput.simplifiedCheckoutInformDepartment)
        selectdSimplified.push(2);
      if (informInput.simplifiedCheckoutInformAddress)
        selectdSimplified.push(3);
      if (informInput.simplifiedCheckoutInformState) selectdSimplified.push(4);
      if (informInput.simplifiedCheckoutInformPhone) selectdSimplified.push(5);
      if (informInput.simplifiedCheckoutInformGender) selectdSimplified.push(6);
      if (informInput.simplifiedCheckoutInformBirthDate)
        selectdSimplified.push(7);
      if (informInput.simplifiedCheckoutInformMemberCode)
        selectdSimplified.push(8);
      this.checkboxListSelectedSimplified = selectdSimplified;
    },
    addConsentTerm() {
      this.campaign.campaignConsentTerms.push(new CampaignConsentTerm());
    },
    removeConsentTerm(index) {
      this.campaign.campaignConsentTerms.splice(index, 1);
    },
  },
};
</script>
